@import "reset";

// Mixins
$breakpoints: (
  sm: 500px,
  md: 725px,
  lg: 1001px
);

@mixin respond-to($size) {
  @media only screen and (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}

@mixin respond-between($min, $max) {
  @media only screen and (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints, $max)) {
    @content;
  }
}

@mixin respond-up-to($size) {
  @media only screen and (max-width: map-get($breakpoints, $size)) {
    @content;
  }
}

// Vars
$globalMaxWidth: 97rem;
$white: #ffffff;
$black: #000000;

.page-inner {
  border: 1px solid #ddd;
}

@font-face {
  font-family: "nmh-font";
  src: url("fonts/nmh-font.eot");
  src: url("fonts/nmh-font.eot?#iefix") format("embedded-opentype"),
    url("fonts/nmh-font.woff") format("woff"),
    url("fonts/nmh-font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before {
  font-family: "nmh-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  font-size: 3.5rem;
  color: #00467d;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:hover:before {
  color: #5abeaa;
}

.icon-language-en:before {
  content: "a";
}

.icon-language-no:before {
  content: "b";
}

.icon-question-mark:before {
  content: "c";
}

html {
  color: #000;
  background: rgb(242, 246, 247);
  font-family: "Arial", sans-serif !important;
  font-weight: 500;
  font-size: 62.5%;
  line-height: 1.5;
}

h1 {
  font-size: 3.6rem;
  line-height: 3.9rem;

  &:first-letter {
    text-transform: capitalize;
  }
}

h2 {
  margin: 0;
  font-size: 2rem;
  line-height: 2.9rem;
  font-weight: normal;
}

p {
  font-size: 1.6rem;
}

body {
  background: #f2f6f7;
  margin: 0;
}

.site-container {
  display: flex;

  &__inner {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

#app-container {
  max-width: 120rem;
  margin: 0 auto;
  flex-grow: 1;
  position: relative;

  @include respond-up-to(sm) {
    background: $white;
  }
}

.header {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 1rem;
  color: $black;
  border-bottom: 2px solid #cacaca;

  @include respond-to(sm) {
    border-bottom: 0;
  }

  line-height: 1;
  //display: flex;
  //align-items: stretch;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    justify-content: flex-start;
  }

  &__logo {
    display: flex;
    padding: 2rem 0;
    align-items: center;

    &__pic {
      @include respond-to(md) {
        height: 65px;
      }

      @include respond-to(lg) {
        height: 78px;
      }

      height: 51px;
      margin-right: 1rem;
    }
  }

  &__links {
    display: flex;
    align-items: center;

    #help-link {
      margin-right: 1rem;
    }

    #toggle-language:hover,
    #help-link:hover {
      text-decoration: none;
    }
  }

  &__top_dynamic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @include respond-up-to(md) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__title {
    padding-right: 2rem;
  }

  &__user-info-bar {
    padding: 0rem;
    justify-content: flex-end;
    align-items: center;
    max-width: $globalMaxWidth;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include respond-to(lg) {
      padding-right: 0;
    }

    &__element {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    &__divider {
      height: 1.5rem;
      width: 0.1rem;
      background-color: #000000;
      margin-left: 1rem;
      margin-right: 2rem;

      @include respond-up-to(sm) {
        display: none;
      }
    }

    &__idporten-logo {
      width: 2.5rem;
      height: 2.5rem;
      background-size: contain;

      @include respond-up-to(mg) {
        margin-right: 0rem;
      }
    }

    &__person {
      background-color: #f2f6f7;
      margin-left: 1rem;

      @include respond-up-to(sm) {
        display: none;
      }
    }

    &__item {
      font-size: 1.5rem;

      a {
        color: #000000;
      }

      margin-right: 2rem;

      @include respond-up-to(sm) {
        margin-right: 0rem;
        margin-left: 1rem;
      }
    }

    &__hide {
      @extend .header__user-info-bar__item;

      @include respond-up-to(sm) {
        display: none;
      }
    }
  }
}

.footer__inner-wrapper {
  max-width: 120rem;
  margin: 0 1rem;
  padding: 3rem 0;

  @include respond-to(lg) {
    margin: 0 auto;
  }

  display: block;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}

.footer {
  margin: 0;
  padding: 1rem;
  background: #0f5aa0;
}

.footer-content {
  color: #ffffff;

  @include respond-to(lg) {
    display: flex;
    justify-content: center;
  }
}

.footer-contact-info,
.footer-maintainer-info,
.footer-terms-info {
  margin-top: 1rem;
  display: block;
  padding-top: 1rem;
  margin-right: 0;

  @media only screen and (min-width: 725px) {
    display: inline-block;
    margin-right: 4rem;
    margin-top: 0;
    padding-top: 0;
  }
}

.info-text {
  font-size: 1.4rem;
  margin-top: 0.6rem;
}

.contact-link,
.uu-info-link,
.cookies-link {
  font-size: 1.4rem;
  margin-top: 1rem;
  color: $white;
  text-decoration: underline;
  border: none;
  box-shadow: none;
  background: #0f5aa0;
  padding: 0.4rem 0;
  transition: all 0.3s ease;
}

.contact-link:hover {
  background: $white;
  color: $black;
}

.contact-link--box-shadow:hover {
  box-shadow: 0 0 0 2px $white;
}
